<template>
  <content-with-sidebar
    v-if="listing"
    class="cws-container cws-sidebar-right blog-wrapper"
  >

    <!-- content -->
    <div class="blog-detail-wrapper">
      <b-row>
        <!-- blogs -->
        <b-col cols="12">
          <b-card>
            <b-carousel
              id="listing-images"
              controls
              indicators
              no-animation
            >
              <b-carousel-slide
                v-for="imageUrl in listing.media['image-urls']"
                :key="imageUrl"
              >
                <template #img>
                  <img
                    class="d-block img-fluid text-center"
                    :src="imageUrl"
                    alt="image slot"
                    style="height:800px; width:auto !important;margin: auto;"
                  >
                </template>
              </b-carousel-slide>

            </b-carousel>

            <b-media no-body>
              <b-media-body>
                <small class="text-muted">photo updated date: {{ listing.timeline['photo-updated-date'] }}</small>
              </b-media-body>
            </b-media>

            <div class="blog-content">
              <b-row>
                <b-col
                  md="6"
                  lg="4"
                >

                  <!-- card 1 -->
                  <b-card
                    :title="listing.location['full-address-short'].toUpperCase()"
                    :footer="listing.timeline['added-at']"
                    footer-class="text-muted"
                  >
                    <b-card-text>
                      {{ listing.location.city.toUpperCase() }}
                    </b-card-text>
                  </b-card>
                </b-col>

                <b-col
                  md="6"
                  lg="4"
                >

                  <!-- card 2 -->
                  <b-card
                    :header="showAttributes(listing.attributes)"
                  >
                    <b-card-text>
                      <strong>Intersection</strong> {{ listing.location.intersection || listing.location.intersection.toUpperCase() }}
                    </b-card-text>
                    <b-card-text>
                      <strong>Community</strong> {{ listing.location['community-name'] || listing.location['community-name'].toUpperCase() }}
                    </b-card-text>
                    <b-card-text>
                      <strong>Region</strong> {{ listing.location.region || listing.location.region.toUpperCase() }}
                    </b-card-text>
                  </b-card>
                </b-col>

                <!-- card 3 -->
                <b-col
                  md="6"
                  lg="4"
                >
                  <b-card
                    :title="showPrice(listing.financial)"
                    class="text-center"
                  >
                    <b-card-text>
                      With supporting text below as a natural.
                    </b-card-text>
                    <div
                      class="demo-inline-spacing"
                      align="center"
                    >
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255 0.15)'"
                        variant="outline-primary"
                      >
                        <feather-icon icon="DollarSignIcon" /> History
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255 0.15)'"
                        variant="outline-primary"
                        :href="listing.media['virtual-tour-url']"
                      >
                        <feather-icon icon="VideoIcon" /> Video
                      </b-button>
                    </div>
                  </b-card>
                </b-col>
              </b-row>
            </div>
            <!-- eslint-enable -->
            <hr class="my-2">

            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="d-flex align-items-center mr-1">
                  <b-link class="mr-50">
                    <feather-icon
                      icon="EyeIcon"
                      size="21"
                      class="text-body"
                    />
                  </b-link>
                  <b-link>
                    <div class="text-body">
                      {{ kFormatter(blogDetail.blog.comments) }}
                    </div>
                  </b-link>
                </div>
                <div class="d-flex align-items-center">
                  <b-link class="mr-50">
                    <feather-icon
                      size="21"
                      icon="BookmarkIcon"
                      class="text-body"
                    />
                  </b-link>
                  <b-link>
                    <div class="text-body">
                      {{ kFormatter(blogDetail.blog.bookmarked) }}
                    </div>
                  </b-link>
                </div>
              </div>

              <!-- dropdown -->
              <div class="blog-detail-share">
                <b-dropdown
                  variant="link"
                  toggle-class="p-0"
                  no-caret
                  right
                >
                  <template #button-content>
                    <feather-icon
                      size="21"
                      icon="Share2Icon"
                      class="text-body"
                    />
                  </template>
                  <b-dropdown-item
                    v-for="icon in socialShareIcons"
                    :key="icon"
                    href="#"
                  >
                    <feather-icon
                      :icon="icon"
                      size="18"
                    />
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <!--/ dropdown -->
            </div>
          </b-card>
        </b-col>
        <!--/ blogs -->

        <!-- About this property -->
        <b-col
          id="aboutProperty"
          cols="12"
          class="mt-2"
        >
          <b-card title="About this property">
            <b-tabs>
              <b-tab active>
                <template #title>
                  <feather-icon
                    v-if="false"
                    icon="ToolIcon"
                  />
                  <span>Description</span>
                </template>

                <b-card-text>
                  {{ listing.attributes['client-remark'] }}
                </b-card-text>
                <b-card-text>
                  {{ listing.attributes['client-remark-extra'] }}
                </b-card-text>
              </b-tab>
              <b-tab>
                <template #title>
                  <feather-icon
                    v-if="false"
                    icon="UserIcon"
                  />
                  <span>Rooms</span>
                </template>

                <b-card>
                  <b-table
                    responsive="sm"
                    :items="listing.attributes.rooms"
                  />
                </b-card>
                <b-card-text class="text-muted">
                  Information provided by external source, may not be reliable.
                </b-card-text>
              </b-tab>
              <b-tab>
                <template #title>
                  <feather-icon
                    v-if="false"
                    icon="HomeIcon"
                  />
                  <span>Timeline</span>
                </template>
                <b-card>
                  <b-table
                    responsive="sm"
                    :items="extra.timeline"
                  />
                </b-card>
              </b-tab>
            </b-tabs>

          </b-card>
        </b-col>
        <!--/ About this property -->

        <!-- Price history -->
        <b-col
          id="priceHistory"
          cols="12"
          class="mt-2"
        >
          <b-card title="Price History">
            <b-table
              responsive="sm"
              :items="extra.priceHistory"
            >
              <template #cell(id)="data">
                <a :href="`/apps/listing/view/${data.value}`">{{ data.value }}</a>
              </template>
            </b-table>

          </b-card>

        </b-col>

        <!-- Community-->
        <b-col
          id="community"
          cols="12"
          class="mt-2"
        >
          <b-card title="Community">
            <b-tabs>
              <b-tab active>
                <template #title>
                  <feather-icon
                    v-if="false"
                    icon="ToolIcon"
                  />
                  <span>School</span>
                </template>

                <b-card-text>
                  <b-table
                    responsive="sm"
                    :items="[
                      { age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
                      { age: 21, first_name: 'Larsen', last_name: 'Shaw' },
                      { age: 89, first_name: 'Geneva', last_name: 'Wilson' },
                      { age: 38, first_name: 'Jami', last_name: 'Carney' }
                    ]"
                  />
                </b-card-text>
              </b-tab>
              <b-tab>
                <template #title>
                  <feather-icon
                    v-if="false"
                    icon="HomeIcon"
                  />
                  <span>Demographics</span>
                </template>
                <b-card>
                  <b-table
                    responsive="sm"
                    :items="[
                      { age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
                      { age: 21, first_name: 'Larsen', last_name: 'Shaw' },
                      { age: 89, first_name: 'Geneva', last_name: 'Wilson' },
                      { age: 38, first_name: 'Jami', last_name: 'Carney' }
                    ]"
                  />
                </b-card>
              </b-tab>
              <b-tab>
                <template #title>
                  <feather-icon
                    v-if="false"
                    icon="HomeIcon"
                  />
                  <span>Commute</span>
                </template>
                <b-card>
                  <b-table
                    responsive="sm"
                    :items="[
                      { age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
                      { age: 21, first_name: 'Larsen', last_name: 'Shaw' },
                      { age: 89, first_name: 'Geneva', last_name: 'Wilson' },
                      { age: 38, first_name: 'Jami', last_name: 'Carney' }
                    ]"
                  />
                </b-card>
              </b-tab>
            </b-tabs>

          </b-card>
        </b-col>
        <!--/ Community -->

        <!-- Similar Active Listings-->
        <b-col
          id="active-listings"
          cols="12"
          class="mt-2"
        >

          <b-card title="Similar active listings">
            <b-row>

              <!-- image top -->
              <b-col
                v-for="li in similarActiveListings"
                :key="li['listing-id']"
                md="6"
                xl="4"
              >
                <b-card
                  :img-src="li.media['image-urls'][0]"
                  img-top
                  img-alt="card img"
                  :title="li.location['full-address-short']"
                  class="mb-3"
                >
                  <b-card-text>
                    {{ li.financial.listed }}
                  </b-card-text>
                  <b-card-text>
                    <small class="text-muted">{{ li.timeline['modified-at'] }}</small>
                  </b-card-text>
                </b-card>
              </b-col>
            </b-row>
          </b-card>

        </b-col>
        <!--/ Similar Active Listings -->

        <!-- Similar Sold Listings-->
        <b-col
          id="sold-listings"
          cols="12"
          class="mt-2"
        >

          <b-card title="Similar Inactive listings">
            <b-row>

              <!-- image top -->
              <b-col
                v-for="li in similarInactiveListings"
                :key="li['listing-id']"
                md="6"
                xl="4"
              >
                <b-card
                  :img-src="li.media['image-urls'][0]"
                  img-top
                  img-alt="card img"
                  :title="li.location['full-address-short']"
                  class="mb-3"
                >
                  <b-card-text>
                    {{ li.financial.listed }}
                  </b-card-text>
                  <b-card-text>
                    <small class="text-muted">{{ li.timeline['modified-at'] }}</small>
                  </b-card-text>
                </b-card>
              </b-col>
            </b-row>
          </b-card>

        </b-col>
        <!--/ Similar Sold Listings -->

        <!-- Leave a Listing Comment -->
        <b-col
          cols="12"
          class="mt-2"
        >
          <h6 class="section-label">
            Inquiry
          </h6>
          <b-card>
            <b-form>
              <b-row>
                <b-col sm="6">
                  <b-form-group class="mb-2">
                    <b-form-input
                      name="name"
                      placeholder="Name"
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group class="mb-2">
                    <b-form-input
                      name="email"
                      type="email"
                      placeholder="Email"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group class="mb-2">
                    <b-form-textarea
                      name="textarea"
                      rows="4"
                      placeholder="Question"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="commentCheckmark"
                    name="checkbox-1"
                    class="mb-2"
                  >
                    Save my name, email, and website in this browser for the next time I comment.
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                  >
                    Post Comment
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-card>
        </b-col>
        <!--/ Leave a Listing Comment -->
      </b-row>
      <!--/ blogs -->
    </div>
    <!--/ content -->

    <!-- sidebar -->
    <div
      slot="sidebar"
      class="blog-sidebar py-2 py-lg-0"
    >
      <!-- input search -->
      <b-form-group class="blog-search">
        <b-input-group class="input-group-merge">
          <b-form-input
            id="search-input"
            v-model="search_query"
            placeholder="Search here"
          />
          <b-input-group-append
            class="cursor-pointer"
            is-text
          >
            <feather-icon
              icon="SearchIcon"
            />
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <!--/ input search -->

      <!-- recent posts -->
      <div class="blog-recent-posts mt-3">
        <h6 class="section-label mb-75">
          Recent Posts
        </h6>
        <b-media
          v-for="(recentpost,index) in blogSidebar.recentPosts"
          :key="recentpost.img"
          no-body
          :class="index? 'mt-2':''"
        >
          <b-media-aside class="mr-2">
            <b-link>
              <b-img
                :src="recentpost.img"
                :alt="recentpost.img.slice(6)"
                width="100"
                rounded
                height="70"
              />
            </b-link>
          </b-media-aside>
          <b-media-body>
            <h6 class="blog-recent-post-title">
              <b-link class="text-body-heading">
                {{ recentpost.title }}
              </b-link>
            </h6>
            <span class="text-muted mb-0">
              {{ recentpost.createdTime }}
            </span>
          </b-media-body>
        </b-media>
      </div>
      <!--/ recent posts -->

      <!-- categories -->
      <div class="blog-categories mt-3">
        <h6 class="section-label mb-1">
          Categories
        </h6>

        <div
          v-for="category in blogSidebar.categories"
          :key="category.icon"
          class="d-flex justify-content-start align-items-center mb-75"
        >
          <b-link>
            <b-avatar
              rounded
              size="32"
              :variant="tagsColor(category.category)"
              class="mr-75"
            >
              <feather-icon
                :icon="category.icon"
                size="16"
              />
            </b-avatar>
          </b-link>
          <b-link>
            <div class="blog-category-title text-body">
              {{ category.category }}
            </div>
          </b-link>
        </div>
      </div>
      <!--/ categories -->
    </div>
  </content-with-sidebar>
</template>

<script>
import {
  BFormInput,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BLink,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BCard,
  BRow,
  BCol,
  BBadge,
  BCardText,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormTextarea,
  BFormCheckbox,
  BButton,
  BTabs,
  BTable,
  BTab, BCarousel, BCarouselSlide,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
// import moment from 'moment'

export default {
  components: {
    BFormInput,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BBadge,
    BCardText,
    BDropdown,
    BForm,
    BDropdownItem,
    BFormTextarea,
    BFormCheckbox,
    BButton,
    BTabs,
    BTab,
    BTable,
    BCarousel,
    BCarouselSlide,
    ContentWithSidebar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      search_query: '',
      commentCheckmark: '',
      blogDetail: [],
      blogSidebar: {},
      socialShareIcons: [
        'GithubIcon',
        'GitlabIcon',
        'FacebookIcon',
        'TwitterIcon',
        'LinkedinIcon',
      ],
      listing: null,
      extra: {
        timeline: [],
        priceHistory: [],
      },
      similarActiveListings: [],
      similarInactiveListings: [],
    }
  },
  async created() {
    this.$http.get('/blog/list/data/detail').then(res => { this.blogDetail = res.data })
    this.$http.get('/blog/list/data/sidebar').then(res => { this.blogSidebar = res.data })

    const query = {
      where: {
        'location.full-address-short': this.$route.params.fullAddressShort.replace(/_/g, ' ').toLowerCase(),
        'location.city': this.$route.params.city.replace(/_/g, ' ').toLowerCase(),
        'listing-id': this.$route.params.listingId.toUpperCase(),
        // _id: this.$route.params.id,
      },
      limit: 1,
    }
    const res = await this.$http.get(`http://localhost:36011/api/listings?filter=${JSON.stringify(query)}`, {
      headers: {
        'x-api-key': 'mongocms',
      },
    })
    // eslint-disable-next-line prefer-destructuring
    this.listing = res.data[0]
    // eslint-disable-next-line no-underscore-dangle
    this.loadTimeline()
    await this.loadPriceHistory()
    await this.loadSimilarListings()
  },
  methods: {
    kFormatter,
    tagsColor(tag) {
      if (tag === 'Quote') return 'light-info'
      if (tag === 'Gaming') return 'light-danger'
      if (tag === 'Fashion') return 'light-primary'
      if (tag === 'Video') return 'light-warning'
      if (tag === 'Food') return 'light-success'
      return 'light-primary'
    },

    showPrice(financial) {
      if (financial.sold > 0) {
        return `$${financial.sold.toLocaleString()}`
      }
      return `$${financial.listed.toLocaleString()}`
    },

    showAddress(location) {
      if (location['unit-number']) {
        return `${location['unit-number']} ${location['full-address-short'].toUpperCase()}`
      }
      return location['full-address-short'].toUpperCase()
    },

    showAttributes(attributes) {
      return `${attributes.bedrooms} Beds - ${attributes.bathrooms} Baths - ${attributes['square-footage'].size || 0} ${attributes['square-footage'].unit}`
    },

    loadTimeline() {
      Object.keys(this.listing.timeline).forEach(key => {
        if (key.endsWith('-at') && this.listing.timeline[key] !== '') {
          // eslint-disable-next-line no-underscore-dangle
          const obj = {
            'time type': key.replaceAll('-', ' '),
            timestamp: (new Date(this.listing.timeline[key])).toLocaleDateString(),
          }
          this.extra.timeline.push(obj)
        }
      })
    },

    async loadPriceHistory() {
      const priceHisotryFilter = {
        where: {
          'location.full-address-short': this.listing.location['full-address-short'],
          'location.city': this.listing.location.city,
        },
        limit: 25,
        order: 'timeline.modified-at DESC',
      }
      const res = await this.$http.get(`http://localhost:36011/api/listings?filter=${JSON.stringify(priceHisotryFilter)}`, {
        headers: {
          'x-api-key': 'mongocms',
        },
      })
      const priceHistory = []
      res.data.forEach(li => {
        const obj = {
          id: li.id,
          type: li['listing-type'],
          listed: `$${li.financial.listed.toLocaleString()}`,
          price: `$${li.financial.sold.toLocaleString()}`,
          'listed date': (new Date(li.timeline['added-at'])).toLocaleDateString(),
          'deal date': (new Date(li.timeline['modified-at'])).toLocaleDateString(),
        }
        priceHistory.push(obj)
      })
      this.extra.priceHistory = priceHistory
    },

    async loadSimilarListingsHelper(type) {
      const filter = {
        where: {
          'location.position': {
            near: this.listing.location.position.coordinates,
            maxDistance: 2500,
            unit: 'meters',
          },
          // 'metadata.source': 'WEB',
          'availability.status': type,
          'attributes.class': 'residential',
          'attributes.type': this.listing.attributes.type,
          'attributes.ownership-type': this.listing.attributes['ownership-type'],
          'media.image-urls': { neq: [] },
          'listing-type': 'sale',
        },
        order: 'timeline.modified-at DESC',
        limit: 3,
        fields: {
          'listing-id': true,
          media: true,
          'listing-type': true,
          location: true,
          financial: true,
          id: true,
          timeline: true,
          attributes: true,
        },
      }

      const res = await fetch(`http://localhost:36011/api/listings?filter=${JSON.stringify(filter)}`, {
        headers: {
          'x-api-key': 'mongocms',
        },
      })
      const data = await res.json()
      return data
    },

    async loadSimilarListings() {
      this.similarActiveListings = await this.loadSimilarListingsHelper('a')
      this.similarInactiveListings = await this.loadSimilarListingsHelper('u')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
